const twenty = [
  'WhatsApp_Image_2020-10-20_at_103129',
  'IMG_8526',
  'IMG_8652',
  'IMG_8653',
  'IMG_8677',
  'IMG_8689',
  'IMG_8733',
  'IMG_8781',
  'TRAX_DESERT_EXPERIENCE_530',
  'TRAX_DESERT_EXPERIENCE_555',
  'TRAX_DESERT_EXPERIENCE_568',
  'TRAX_DESERT_EXPERIENCE_620',
  'TRAX_DESERT_EXPERIENCE_632',
  'TRAX_DESERT_EXPERIENCE_719',
];

export default twenty;
