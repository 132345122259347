const twelve = [
  '2012-08-12_222826',
  'IMG_0514',
  'IMG_0515',
  'IMG_0516',
  'IMG_0587',
  'IMG_0612',
  'IMG_0613',
  'IMG_0614',
  'IMG_0615',
  'IMG_0640',
  'IMG_0641',
  'IMG_0642',
  'IMG_0647',
  'IMG_0649',
  'IMG_0682',
  'IMG_0683',
  'IMG_0684',
  'IMG_0778',
  'IMG_0820',
  'IMG_0821',
  'IMG_0822',
  'IMG_0838',
  'IMG_0839',
  'IMG_1436',
  'IMG_1437',
  'Lunch_break–during_training_P&G_Venezuela',
  'Training_P&G_Venezuela_1',
  'Training_P&G_Venezuela_2',
  'WhatsApp–Image-2020-10-21',
];

export default twelve;
