import {
  eleven,
  twelve,
  thirteen,
  fourteen,
  fifteen,
  sixteen,
  seventeen,
  eighteen,
  nineteen,
  twenty,
} from './images';

const years = [
  {
    key: 'Ten',
    year: '2010',
    src: null,
  },
  {
    key: 'Eleven',
    year: '2011',
    src: `/timeline/2011/${eleven[0]}.jpg`,
    images: eleven,
  },
  {
    key: 'Twelve',
    year: '2012',
    src: `/timeline/2012/${twelve[0]}.jpg`,
    images: twelve,
  },
  {
    key: 'Thirteen',
    year: '2013',
    src: `/timeline/2013/${thirteen[0]}.jpg`,
    images: thirteen,
  },
  {
    key: 'Fourteen',
    year: '2014',
    src: `/timeline/2014/${fourteen[0]}.jpg`,
    images: fourteen,
  },
  {
    key: 'Fifteen',
    year: '2015',
    src: `/timeline/2015/${fifteen[0]}.jpg`,
    images: fifteen,
  },
  {
    key: 'Sixteen',
    year: '2016',
    src: `/timeline/2016/${sixteen[0]}.jpg`,
    images: sixteen,
  },
  {
    key: 'Seventeen',
    year: '2017',
    src: `/timeline/2017/${seventeen[0]}.jpg`,
    images: seventeen,
  },
  {
    key: 'Eighteen',
    year: '2018',
    src: `/timeline/2018/${eighteen[0]}.jpg`,
    images: eighteen,
  },
  {
    key: 'Nineteen',
    year: '2019',
    src: `/timeline/2019/${nineteen[0]}.jpg`,
    images: nineteen,
  },
  {
    key: 'Twenty',
    year: '2020',
    src: `/timeline/2020/${twenty[0]}.jpg`,
    images: twenty,
  },
];

const mergeTimelineWithYears = (timeline) =>
  years.map((year) => {
    const timelineContent = timeline[year.key];
    return { ...year, ...timelineContent };
  });

export default mergeTimelineWithYears;
