/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import clsx from 'clsx';

const TimelineYear = ({ entry, openGallery }) => {
  const { year, src, content } = entry;
  const containerClass = clsx('w-full lg:flex relative', {
    'flex-row-reverse': year % 2 === 0,
  });

  const yearClass = clsx(
    'lg:w-1/2 mb-16 lg:mb-0',
    year % 2 === 0
      ? 'lg:-mr-1 lg:border-r-4 border-purple'
      : 'lg:border-l-4 border-purple'
  );

  return (
    <div className={containerClass}>
      <div className="lg:w-1/2 h-5"> </div>
      <div className={yearClass}>
        <div className="max-w-md mx-auto pl-5 pb-5 pr-5 xl:mr-16 xl:ml-16">
          <p className="text-orangepast text-opacity-25 mb-8 text-6xl font-bold">
            {year}
          </p>
          <p className="mb-10 text-base">{content}</p>
          {src && (
            <div
              className="relative rounded-lg overflow-hidden timeline-gallery"
              onClick={() => openGallery(year)}
            >
              <img src={src} className="rounded-md" width="414" alt="Team" />
              <div className="timeline-gallery--text absolute w-full h-full top-0 left-0 text-center text-white flex items-center justify-center bg-black bg-opacity-75 cursor-pointer shadow-lg">
                <h3>Click to open gallery</h3>
              </div>
            </div>
          )}
        </div>
      </div>
      <img
        src="/icons/circle.svg"
        width="20"
        alt="Point"
        className="hidden lg:block absolute buletpoint"
        // style={{ right: '48.8%' }}
      />
    </div>
  );
};

export default TimelineYear;
