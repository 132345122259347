const thirteen = [
  'Training_P&G_Brazil_3',
  '1012066_10153803778770548_593327757_n',
  '2013-02-14_164619',
  '20131220_151655',
  '20131220_160636',
  'IMG-20140410-WA0000',
  'IMG-20150707-WA0007',
  'IMG_1445',
  'IMG_1446',
  'IMG_1447',
  'IMG_1449',
  'IMG_1622',
  'IMG_1646',
  'IMG_1651',
  'IMG_1663',
  'IMG_1664',
  'IMG_1665',
  'IMG_1666',
  'IMG_1667',
  'IMG_2177',
  'Training_P&G_Brazil_1',
  'Training_P&G_Brazil_2',
  'WhatsApp_Image_2020-10-21_at_101104',
  'WhatsApp_Image_2020-10-21_at_101104_1',
  'WhatsApp_Image_2020-10-21_at_101415',
  'WhatsApp_Image_2020-10-21_at_101415_1',
  'WhatsApp_Image_2020-10-21_at_102338',
];

export default thirteen;
