const eighteen = [
  '20180606_105905',
  '20180107_150826',
  '20180123_114323',
  '20180201_152448',
  '20180207_140848',
  '20180208_161605',
  '20180211_140903',
  '20180211_144717',
  '20180213_145307',
  '20180218_140451',
  '20180218_140456',
  '20180304_160021',
  '20180328_184346',
  '20180329_133945',
  '20180329_133954',
  '20180411_111623',
  '20180411_150612',
  '20180412_143200',
  '20180415_141138',
  '20180415_141331',
  '20180429_142320',
  '20180430_132659',
  '20180503_100603',
  '20180524_180516',
  '20180527_174032',
  '20180530_141110',
  '20180531_151253',
  '20180605_110246',
  '20180605_145735',
  '20180605_162131',
  '20180605_162747',
  '20180605_162800',
  '20180605_172232',
  '20180605_172545',
  '20180605_180535',
  '20180605_193346',
  '20180606_160927',
  '20180606_204933',
  '20180607_163935',
  '20180607_165840_001',
  '20180610_140533',
  '20180625_155515',
  '20180701_160703',
  '20180708_140743',
  '20180805_142649',
  '20180805_142701',
  '20180812_140814',
  '20181004_150505',
  '20181007_143243',
  '20181007_143247',
  '20181108_145343',
  '20181220_145825',
  '84D8ACC0-0139-47E6-A728-E94322703203',
  'IMG_0015',
  'IMG_0034',
  'IMG_0035_2',
  'IMG_0041',
  'IMG_0043',
  'IMG_0044',
  'IMG_0045',
  'IMG_0046',
  'IMG_9201JPG',
  'IMG_9201JPG',
  'IMG_9201JPG',
  'WhatsApp_Image_2020-10-15_at_103857',
  'WhatsApp_Image_2020-10-21_at_095512',
  'WhatsApp_Image_2020-10-21_at_095634',
  'WhatsApp_Image_2020-10-21_at_095811',
  'WhatsApp_Image_2020-10-21_at_095934',
  '20181112_095917',
  '20181112_101416',
  '20181112_103012',
  '69e0ed62-36c5-4d0d-891e-e0060dc24591JPG',
  'IMG-20181113-WA0039',
  'IMG_0014',
  'IMG_1846JPG',
  'IMG_1862JPG',
  'IMG_1890JPG',
  'IMG_1924JPG',
  'IMG_1930JPG',
  'IMG_1938JPG',
  'IMG_20180727_183330',
  'IMG_20180727_190330',
  'IMG_20180727_190421',
  'IMG_4696JPG',
  'IMG_9192JPG',
  'IMG_9202JPG',
  'IMG_9208JPG',
  'IMG_9212JPG',
  'IMG_9213JPG',
  'TAM_6036JPG',
  'TAM_6052JPG',
  'TAM_6149JPG',
  'TAM_6180JPG',
  'TAM_6196JPG',
  'TAM_6227JPG',
  'TAM_6274JPG',
  'Tomer_Foltyn_2018_02_20_Trax_Purim_142',
  'Tomer_Foltyn_2018_02_20_Trax_Purim_Magnets_230',
  'Tomer_Foltyn_2018_02_20_Trax_Purim_Magnets_237',
  'Tomer_Foltyn_2018_02_20_Trax_Purim_Magnets_267',
  '20181112_101659',
  'Tomer_Foltyn_2018_02_20_Trax_Purim_Magnets_175',
  'Tomer_Foltyn_2018_02_20_Trax_Purim_Magnets_187',
  'Tomer_Foltyn_2018_02_20_Trax_Purim_Magnets_197',
  'Tomer_Foltyn_2018_02_20_Trax_Purim_Magnets_207',
];

export default eighteen;
