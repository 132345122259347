import React from 'react';
import Modal from 'react-responsive-modal';
import { Carousel } from 'react-responsive-carousel';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '10',
  },
};

const TimelineGallery = ({ setOpen, open, children }) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onRequestClose={() => setOpen(false)}
      center
      focusTrapped={false}
      showCloseIcon={false}
      style={customStyles}
      classNames={{
        overlay: 'modal-overlay',
        modal: 'modal-dark',
      }}
    >
      <Carousel useKeyboardArrows infiniteLoop showIndicators={false}>
        {children}
      </Carousel>
    </Modal>
  );
};

export default TimelineGallery;
