const fifteen = [
  'WhatsApp_Image_2020-10-21_at_104627_2',
  '2015-03-05_161134',
  '2015-06-09_141729',
  '2015-07-29_131306',
  '2015-07-31_153211',
  '2015-08-17_003935-2',
  '2015-11-04_212406',
  '2015-11-12_172629',
  '20151104_203213',
  'FB_IMG_1450769490544',
  'IMG-20141216-WA0000',
  'IMG-20150528-WA0000',
  'IMG-20150826-WA0000',
  'IMG-20151123-WA0000',
  'IMG_20150119_154458',
  'IMG_20150120_180120',
  'IMG_20150212_143057',
  'IMG_20150305_160755',
  'IMG_20150305_161134',
  'IMG_20150402_163509',
  'IMG_20150402_163517',
  'IMG_20150609_141357',
  'IMG_20150609_141625',
  'IMG_20150827_170357',
  'IMG_20150910_125733',
  'IMG_20151029_155850',
  'IMG_20151104_202919',
  'IMG_20151104_210256',
  'IMG_20151104_210534',
  'IMG_20151104_212430',
  'IMG_20151201_160039',
  'IMG_20151224_110825',
  'IMG_20151224_110840',
  'IMG_20151224_111048',
  'IMG_20151224_123214',
  'IMG_20151224_193300',
  'IMG_20151224_193408',
  'IMG_20151224_193423',
  'IMG_20151224_193444',
  'IMG_20151224_193501',
  'IMG_20151224_193742',
  'IMG_20151231_155901',
  'IMG_20151231_163548',
  'IMG_3966',
  'IMG_3991',
  'IMG_4116',
  'IMG_4129',
  'IMG_7868',
  'IMG_7869',
  'IMG_7871',
  'IMG_7873',
  'IMG_7874',
  'WhatsApp_Image_2020-10-21_at_102805',
  'WhatsApp_Image_2020-10-21_at_102806',
  'WhatsApp_Image_2020-10-21_at_102859',
  'WhatsApp_Image_2020-10-21_at_102859_1',
  'WhatsApp_Image_2020-10-21_at_102859_2',
  'WhatsApp_Image_2020-10-21_at_102859_3',
  'WhatsApp_Image_2020-10-21_at_102859_4',
  'WhatsApp_Image_2020-10-21_at_103332',
  'WhatsApp_Image_2020-10-21_at_103333',
  'WhatsApp_Image_2020-10-21_at_104627',
  'WhatsApp_Image_2020-10-21_at_104627_1',
  'WhatsApp_Image_2020-10-21_at_104627_3',
  'WhatsApp_Image_2020-10-21_at_105442',
  'WhatsApp_Image_2020-10-21_at_105531',
  'WhatsApp_Image_2020-10-21_at_105532',
  'WhatsApp_Image_2020-10-21_at_105532_1',
  'WhatsApp_Image_2020-10-21_at_105533',
  'WhatsApp_Image_2020-10-21_at_105533_1',
  'WhatsApp_Image_2020-10-21_at_105533_2',
  'WhatsApp_Image_2020-10-21_at_105533_3',
  'WhatsApp_Image_2020-10-21_at_105533_4',
  'old_SG_office',
  'rsz_traxretail_16_2',
];

export default fifteen;
