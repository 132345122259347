const seventeen = [
  'WhatsApp_Image_2020-10-21_at_105909_4',
  '17361865_10213033747220665_6783497125428594749_n',
  '2017-03-19_184845',
  '20171031_144424',
  '20171109_094227',
  '20171109_094244',
  '20171115_161400',
  '20171116_110059',
  '20171116_151800',
  '20171119_141657',
  '20171126_140626',
  '20171127_194055',
  '20171127_202127',
  '20171128_105927',
  '20171130_121123',
  '20171203_140427',
  '20171204_124235',
  '20171205_100504',
  '20171206_153116',
  '20171214_093726',
  '20171214_132939',
  '20171214_142014',
  '20171219_143223',
  '20171231_141033',
  '34787941_10217284774653694_5785294731231625216_n',
  'IMG-20160131-WA0001',
  'IMG_3185JPG',
  'IMG_3187JPG',
  'IMG_4027JPG',
  'IMG_4039JPG',
  'IMG_4046JPG',
  'IMG_4302JPG',
  'IMG_4312JPG',
  'IMG_4366JPG',
  'IMG_4368JPG',
  'IMG_4375JPG',
  'IMG_4383JPG',
  'IMG_4962JPG',
  'IMG_4972JPG',
  'IMG_4977JPG',
  'IMG_5948JPG',
  'IMG_7973JPG',
  'IMG_8201JPG',
  'IMG_8202JPG',
  'IMG_8206JPG',
  'IMG_8211JPG',
  'IMG_8228JPG',
  'IMG_8240JPG',
  'Tomer_Foltyn_2017_07_06_Trax_Thai_Party_037',
  'Tomer_Foltyn_2017_07_06_Trax_Thai_Party_067',
  'Tomer_Foltyn_2017_07_06_Trax_Thai_Party_071',
  'Tomer_Foltyn_2017_07_06_Trax_Thai_Party_162',
  'WhatsApp_Image_2020-10-15_at_104223',
  'WhatsApp_Image_2020-10-20_at_104433',
  'WhatsApp_Image_2020-10-21_at_095354',
  'WhatsApp_Image_2020-10-21_at_095430',
  'WhatsApp_Image_2020-10-21_at_095712',
  'WhatsApp_Image_2020-10-21_at_100212',
  'WhatsApp_Image_2020-10-21_at_100223',
  'WhatsApp_Image_2020-10-21_at_100247',
  'WhatsApp_Image_2020-10-21_at_100344',
  'WhatsApp_Image_2020-10-21_at_105318',
  'WhatsApp_Image_2020-10-21_at_105909',
  'WhatsApp_Image_2020-10-21_at_105909_1',
  'WhatsApp_Image_2020-10-21_at_105909_3',
  'WhatsApp_Image_2020-10-21_at_105909_5',
  'WhatsApp_Image_2020-10-21_at_105909_6',
  'slashWEBsmall',
  'DSC_0207',
  'DSC_0246',
  'GOPR8671',
  'IMG_8224JPG',
  'IMG_8240JPG',
  'DSC_0233',
  'GOPR8461',
  'IMG_8213JPG',
  'IMG_8233JPG',
  'IMG_8268JPG',
];

export default seventeen;
