import React, { useState } from 'react';
import { graphql } from 'gatsby';

import {
  TimelineHero,
  TimelineYear,
  TimelineGallery,
} from '../components/timeline';
import mergeTimelineWithYears from '../components/timeline/timeline-data';

const FOLDER_PATH = '/timeline';
const IMAGE_EXTENSION = '.jpg';

const TimelinePage = ({ data }) => {
  const timeline = data.strapiTimelinePage;
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState('');

  const timelineMerged = mergeTimelineWithYears(timeline);

  const openGallery = (year) => {
    setCurrent(year);
    setOpen(true);
  };

  const currentYear = timelineMerged.find((entry) => entry.year === current);

  return (
    <section className="pt-4">
      <TimelineHero />

      <TimelineGallery open={open} setOpen={setOpen}>
        {currentYear?.images?.map((imageName) => {
          const imageUrl = `${FOLDER_PATH}/${currentYear.year}/${imageName}${IMAGE_EXTENSION}`;
          return (
            <div key={imageName}>
              <img src={imageUrl} alt="" />
              {/* <p className="legend">{imageName}</p> */}
            </div>
          );
        })}
      </TimelineGallery>

      <div id="timeline" className="container mt-20 mb-20">
        {timelineMerged.map((entry) => (
          <TimelineYear
            key={entry.key}
            entry={entry}
            openGallery={openGallery}
          />
        ))}
      </div>
    </section>
  );
};

export default TimelinePage;

export const query = graphql`
  query TimelineQuery {
    strapiTimelinePage {
      Ten {
        content
      }
      Eleven {
        content
      }
      Twelve {
        content
      }
      Thirteen {
        content
      }
      Fourteen {
        content
      }
      Fifteen {
        content
      }
      Sixteen {
        content
      }
      Seventeen {
        content
      }
      Eighteen {
        content
      }
      Nineteen {
        content
      }
      Twenty {
        content
      }
    }
  }
`;
